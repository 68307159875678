import _descriptors from "../internals/descriptors";
import _global3 from "../internals/global";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _isForced from "../internals/is-forced";
import _defineBuiltIn from "../internals/define-built-in";
import _hasOwnProperty from "../internals/has-own-property";
import _inheritIfRequired from "../internals/inherit-if-required";
import _objectIsPrototypeOf from "../internals/object-is-prototype-of";
import _isSymbol from "../internals/is-symbol";
import _toPrimitive from "../internals/to-primitive";
import _fails from "../internals/fails";
import _objectGetOwnPropertyNames from "../internals/object-get-own-property-names";
import _objectGetOwnPropertyDescriptor from "../internals/object-get-own-property-descriptor";
import _objectDefineProperty from "../internals/object-define-property";
import _thisNumberValue from "../internals/this-number-value";
import _stringTrim from "../internals/string-trim";
var DESCRIPTORS = _descriptors;
var _global2 = _global3;
var uncurryThis = _functionUncurryThis;
var isForced = _isForced;
var defineBuiltIn = _defineBuiltIn;
var hasOwn = _hasOwnProperty;
var inheritIfRequired = _inheritIfRequired;
var isPrototypeOf = _objectIsPrototypeOf;
var isSymbol = _isSymbol;
var toPrimitive = _toPrimitive;
var fails = _fails;
var getOwnPropertyNames = _objectGetOwnPropertyNames.f;
var getOwnPropertyDescriptor = _objectGetOwnPropertyDescriptor.f;
var defineProperty = _objectDefineProperty.f;
var thisNumberValue = _thisNumberValue;
var trim = _stringTrim.trim;
var NUMBER = "Number";
var NativeNumber = _global2[NUMBER];
var NumberPrototype = NativeNumber.prototype;
var TypeError = _global2.TypeError;
var arraySlice = uncurryThis("".slice);
var charCodeAt = uncurryThis("".charCodeAt); // `ToNumeric` abstract operation
// https://tc39.es/ecma262/#sec-tonumeric

var toNumeric = function (value) {
  var primValue = toPrimitive(value, "number");
  return typeof primValue == "bigint" ? primValue : toNumber(primValue);
}; // `ToNumber` abstract operation
// https://tc39.es/ecma262/#sec-tonumber


var toNumber = function (argument) {
  var it = toPrimitive(argument, "number");
  var first, third, radix, maxCode, digits, length, index, code;
  if (isSymbol(it)) throw TypeError("Cannot convert a Symbol value to a number");

  if (typeof it == "string" && it.length > 2) {
    it = trim(it);
    first = charCodeAt(it, 0);

    if (first === 43 || first === 45) {
      third = charCodeAt(it, 2);
      if (third === 88 || third === 120) return NaN; // Number('+0x1') should be NaN, old V8 fix
    } else if (first === 48) {
      switch (charCodeAt(it, 1)) {
        case 66:
        case 98:
          radix = 2;
          maxCode = 49;
          break;
        // fast equal of /^0b[01]+$/i

        case 79:
        case 111:
          radix = 8;
          maxCode = 55;
          break;
        // fast equal of /^0o[0-7]+$/i

        default:
          return +it;
      }

      digits = arraySlice(it, 2);
      length = digits.length;

      for (index = 0; index < length; index++) {
        code = charCodeAt(digits, index); // parseInt parses a string to a first unavailable symbol
        // but ToNumber should return NaN if a string contains unavailable symbols

        if (code < 48 || code > maxCode) return NaN;
      }

      return parseInt(digits, radix);
    }
  }

  return +it;
}; // `Number` constructor
// https://tc39.es/ecma262/#sec-number-constructor


if (isForced(NUMBER, !NativeNumber(" 0o1") || !NativeNumber("0b1") || NativeNumber("+0x1"))) {
  var NumberWrapper = function Number(value) {
    var n = arguments.length < 1 ? 0 : NativeNumber(toNumeric(value));
    var dummy = this; // check on 1..constructor(foo) case

    return isPrototypeOf(NumberPrototype, dummy) && fails(function () {
      thisNumberValue(dummy);
    }) ? inheritIfRequired(Object(n), dummy, NumberWrapper) : n;
  };

  for (var keys = DESCRIPTORS ? getOwnPropertyNames(NativeNumber) : ( // ES3:
  "MAX_VALUE,MIN_VALUE,NaN,NEGATIVE_INFINITY,POSITIVE_INFINITY," + // ES2015 (in case, if modules with ES2015 Number statics required before):
  "EPSILON,MAX_SAFE_INTEGER,MIN_SAFE_INTEGER,isFinite,isInteger,isNaN,isSafeInteger,parseFloat,parseInt," + // ESNext
  "fromString,range").split(","), j = 0, key; keys.length > j; j++) {
    if (hasOwn(NativeNumber, key = keys[j]) && !hasOwn(NumberWrapper, key)) {
      defineProperty(NumberWrapper, key, getOwnPropertyDescriptor(NativeNumber, key));
    }
  }

  NumberWrapper.prototype = NumberPrototype;
  NumberPrototype.constructor = NumberWrapper;
  defineBuiltIn(_global2, NUMBER, NumberWrapper);
}

export default {};